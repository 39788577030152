<template>
<v-app-bar app color="white"  absolute class="nav pa-0 pb-2">
  <div class="d-flex align-center  justify-space-between">
    <router-link :to="userId === null || userId === 'undefined' ? '/' : '/my'" class="d-flex">

        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2 d-inline-flex ml-2"
          contain
          src="@/assets/Logo-Color.png"
          transition="scale-transition"
          width="50"
        />
        <v-img
        v-if="$vuetify.breakpoint.mdAndUp"
        alt="Vuetify Name"
        class="shrink hidden-sm-and-down d-inline-flex"
        contain
        min-width="80"
        src="@/assets/Logo-Text.png"
        width="80"
        />
    </router-link>
  </div>


    <template v-if="$vuetify.breakpoint.xs">
        <v-spacer></v-spacer>
      <v-dialog v-model="dialog" fullscreen>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="grey"
            v-bind="attrs"
            v-on="on"

            text
          >
            <v-icon>mdi-magnify</v-icon> Search
          </v-btn>
        </template>

        <v-card>
          <v-card-title class="headline bg-loop white--text">
            Search Skills/People
            <v-spacer></v-spacer>
            <v-btn
              color="black"
              text
              @click="dialog = false"
            >
              Close
            </v-btn>
          </v-card-title>
          <v-card-text class="px-3 pt-4 grey lighten-3">

            <search-mobile @close-modal="closeModal"></search-mobile>
          </v-card-text>


          <v-divider></v-divider>

          <!-- <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="black"
              text
              @click="dialog = false"
            >
              Close
            </v-btn>
          </v-card-actions> -->
        </v-card>
      </v-dialog>
    </template>
    <template v-else>
      <div class="col-md-6 col-sm-6 col-8">
        <search-lite></search-lite>
      </div>
  </template>


  <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>

  <v-menu offset-y v-if="user !== null">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            class="mr-md-2 py-md-5"
            icon>
            <!-- <span class="pr-2" v-if="$vuetify.breakpoint.mdAndUp">{{user.firstName}}</span> -->
            <!-- <v-icon>mdi-arrow-top-right</v-icon> -->
            <template v-if="!$isEmpty(user.photoURL)">
              <v-avatar color="primary" size="32"><img :src="user.photoURL.thumb" alt=""></v-avatar>
            </template>
            <template v-else>
              <v-avatar color="primary" size="32"><img :src="user.basicAvatar" alt=""></v-avatar>
            </template>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in menuFiltered"
            :key="index"
            :to="item.path"
            exact
          >
            <v-list-item-title >{{ item.text }}</v-list-item-title>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item :to="`/@${user.userName}`">
            <v-list-item-title >Public Profile</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/edit-profile`">
            <v-list-item-title >Edit Profile</v-list-item-title>
          </v-list-item>

          <v-divider></v-divider>


          <v-list-item @click="logout">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <template v-else>
        <v-btn dark color="black" v-if="$vuetify.breakpoint.smAndUp">Become Meetable</v-btn>
        <v-btn v-else color="primary" text>Join</v-btn>
        <v-btn class="mx-sm-2" to="/login" text>
          <span>Login</span>
        </v-btn>
      </template>


</v-app-bar>
</template>

<script>
import {db} from '@/firebase';
const search = () => import("@/components/shared/search.vue");
const searchLite = () => import("@/components/shared/search-lite.vue");
const searchMobile = () => import("@/components/shared/search-lite-mobile.vue");
export default {
  name: 'navLogged',
  components:{searchLite, searchMobile},
  computed:{
    menu: function(){return this.$store.state.mobile_menu},
    menuFiltered: function(){
      return this.menu.filter(item =>  item.text !== 'Search')
    }
  },
  data: () => ({
    photoURL: null,
    user:null,
    userId: null,
    dialog: false,
  }),
  methods:{
    logout(){
      let vThis = this;
      this.$store.dispatch('logout').then(x=>{
        vThis.$router.push('/')
      });
    },
    closeModal(val){
      process.env.NODE_ENV === "development" ? console.log( 'close modal', val ) : null;
      if(val === true){this.dialog = false}
    },

    setupUser(){
      let vThis = this;
      this.$store.dispatch('getCurrentUser').then(user => {
        vThis.user = user.data;
        vThis.userId = user.userId;
        // vThis.menu = [
        // {text:'Dashboard', to:`/my`},
        // {text:'Meetings', to:`/my/calendar`},
        // {text:'Proposals', to:`/my/proposals`},
        // {text:'Settings', to:`/my/settings`},
        // ]
        // vThis.getMeetings(user.userId);
        vThis.getProposals(user.userId)
      })
    },

    //
    //
    //load meetingsLoaded
    getProposals(userId){
      let vThis = this;
      this.$store.dispatch('getProposals', {userId: userId, flag: 'active'});
    },
    getMeetings(userId){
      let vThis = this;
      this.$store.dispatch('getMeetings', userId);
    },
  },
  mounted(){
    this.setupUser();



  }
};
</script>


<style lang="scss">
.nav{
  .v-toolbar__content{
    // padding-right: 0;
    // padding-bottom: 0;
    padding:0!important;
  }
  .nav-links{
    a{
      text-decoration: none;
      font-weight: bold;
      display: inline-block;
      margin-right: 16px;
      padding: 4px 8px;
      color: rgba(0,0,0,.5)!important;
      font-size: 1.1rem;
    }
  }
}
</style>
